<template>
  <section class="m-goods m-scroll"  @scroll="handleScroll($event)"  ref="wrapper">

    <!-- 视频 & 轮播图 -->
    <goods-swipe ref="banner" :images="goodsInfo.round_pic_url" :videoData="goodsInfo.video_url == '' ? {} : goodsInfo.video_url"
    :hasVideo="goodsInfo.video_url != null && goodsInfo.video_url != ''" :goodsInfo="goodsInfo"></goods-swipe>

    <!-- 头部导航 -->
    <div class="head-bar m-padding" ref="bar">
      <img @click="goodsBack" src="@/assets/goods/icon_back_2@2x.png" alt="img">
      <div>
        <router-link to="/en/cart" >
          <img src="@/assets/goods/cart.png" alt="img" :class="{'cart':cartAnimate}">
          <m-dot :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
        <router-link to="/en">
          <img src="@/assets/goods/icon_home_3@2x.png" alt="">
        </router-link>
      </div>
    </div>
    <div class="goods">
      <!-- 商品价格 -->
      <div class="goods-price m-padding">
        <div class="price-left">
          <div class="title">Super deal</div>
          <div class="price">
            Super Price<span>${{haggleInfo.bargain_goods_sku[0].sales_price}}</span><span>${{haggleInfo.bargain_goods_sku[0].goods_sku.price}}</span>
          </div>
        </div>
        <div class="price-right">
          <div class="over">Ends in</div>
          <div class="count">
            <van-count-down :time="haggleInfo.remaining_time*1000">
              <template #default="timeData">
                <span class="block">{{ timeData.days > 9 ? timeData.days : '0' + timeData.days }}day</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.hours  > 9 ? timeData.hours : '0' + timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
      <!-- 商品名称 -->
      <div class="goods-title is-margin">
        <div class="title">
          <p class="van-multi-ellipsis--l2">{{goodsInfo.goods_info.name}}</p>
          <div class="rules" @click="rule">
            <p>rules</p>
            <!-- <p></p> -->
          </div>
        </div>
        <!-- <div class="hanggle">
          <div>
            <p>限购数量</p>
            <p>1222</p>
          </div>
          <div>
            <p>砍价库存</p>
            <p>1222</p>
          </div>
          <div>
            <p>参加人数</p>
            <p>1222</p>
          </div>
        </div> -->
      </div>
      <div class="haggle-play is-margin">
        <div class="play-title">How to get the super deal ?</div>
        <div class="step1">
          <img src="@/assets/haggle/choose.png" alt="">
          <p></p>
          <img src="@/assets/haggle/invite.png" alt="">
          <p></p>
          <img src="@/assets/haggle/pay.png" alt="">
        </div>
        <div class="step2">
          <p>Choose your<br>wanted product</p>
          <p>Share the link <br> to friends</p>
          <p>Ask more friends <br> click the link</p>
        </div>
      </div>
      <!-- 商品服务；选择SKU -->
      <!-- <div class="sku-select is-margin">
        <div class="servies-cell">
          <p>服务</p>
          <p v-if="goodsInfo.after_sale == 1">该商品支持七天无理由退货</p>
          <p v-else-if="goodsInfo.after_sale == 2">非质量问题不退不换</p>
          <p v-else>无售后</p>
        </div>
        <div class="servies-cell" v-if="goodsInfo.after_sale_content">
          <p>说明</p>
          <p>{{goodsInfo.after_sale_content}}</p>
        </div>
        <div class="sku-cell" @click="handleShowSku" v-if="!haggleShow">
          <p>请选择</p>
          <p>{{selectSkuList.join(' | ') || '暂无选择'}}</p>
          <img src="@/assets/order/icon-fanhui@2x.png" alt="img">
        </div>
      </div> -->
      <!-- 底部控制栏 -->
      <div class="foot-bar">
        <router-link to="/en/mooby?id=4" v-if="goodsInfo.merchant_id == 1 && goodsInfo.pre_info.is_pre == 0">
          <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
          <!-- <span>自营</span> -->
        </router-link>
        <router-link :to="`/en/merchant/index/${goodsInfo.merchant_id}`" v-if="goodsInfo.merchant_id != 1">
          <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
          <!-- <span>店铺</span> -->
        </router-link>
        <router-link to="/en/customer">
          <img src="@/assets/goods/icon_kefu@2x.png" alt="servies">
          <!-- <span>客服</span> -->
        </router-link>
        <span @click="favoriteGoodsHandle">
          <img src="@/assets/goods/icon_shouchang@2x.png" alt="img" v-if="goodsInfo.goods_collect == null">
          <img src="@/assets/goods/icon_shouchang@2x_a.png" alt="img" v-else>
          <!-- <span>收藏</span> -->
        </span>
        <div class="btns">
          <span @click="showSkuModel(skuBtn)" v-if="!haggleShow"><span>Super Price ${{haggleInfo.bargain_goods_sku[0].sales_price}}</span> <br><span>Open the Deal</span></span>
          <p @click="handleBargain" v-else><span>check current process</span></p>
        </div>
      </div>
    </div>
    <!-- 活动规则 -->
    <m-rule @rule="rule" :ruleShow="ruleShow"></m-rule>

    <!-- 回到顶部 -->
    <m-scroll-top :show="scrollTop" :dom="$refs.wrapper"></m-scroll-top>
    <!-- 商品详情图 -->
    <m-divider title="Product Image Gallery" class="detail-title"></m-divider>
    <div class="detail-img">
      <img :src="item.url" v-for="(item, index) in goodsInfoImg" :key="index" alt="">
    </div>
    <!-- 选择SKU 加入购物车 -->
    <m-sku :title="skuBtn" :show="skuModal" @select="selectSku"
    @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId" :off="off" :haggle="1" ></m-sku>
    <!-- 快速下单 -->
    <transition name="van-fade">
      <Qlogin @success="loginSuccess" @close="isViewLogin = false" v-if="isViewLogin" />
    </transition>
  </section>
</template>

<script>
import GoodsSwipe   from '@/components/en/goods-swipe.vue'
import MDot         from '@/components/en/m-dot.vue'
import MSku         from '@/components/en/m-sku.vue'
import MDivider     from '@/components/en/m-divider.vue'
import MScrollTop   from '@/components/en/m-scroll-top.vue'
import Qlogin       from '../goods/qlogin.vue'
import MRule from './rule.vue'

import { _isWechat,_isIOS } from '@/untils/js/common.js'
import { getGoodsInfo, checkLogin, weshare, } from '@/api/zh/detail.js'
import { favoriteGoods } from '@/api/zh/mine.js'
import { haggle_detail } from '@/api/zh/haggle.js'
export default {
  name:'Index',
  components:{ GoodsSwipe, MDot, MSku, MDivider, MScrollTop, Qlogin, MRule },
  data(){
    return {
      loading:false,
      skuModal:false,
      goodsId: '',
      off: 0,
      cartAnimate:false,
      dotAnimate:false,
      scrollTop:false,
      goodsInfo: {
        min_price: 0,
        max_price: 0,
        off: 0,
        pre_info: '',
        is_pre: '',
        goods_info: ''
      },
      haggleInfo: {bargain_goods_sku:[{sales_price:'',goods_sku:{}}],remaining_time:0},
      goodsInfoImg: {},
      skuData: [],
      skuBtn : 'Super Price $0 Open the Deal',
      isNew: false,
      selectSkuList: [],
      timer: null,
      isViewLogin: false,
      isQOrder: false,
      sharePrice: 0,
      sharePrePrice: 0,
      costPrice: 0,
      shareBarcode: '',
      isPre: false,
      haggleShow: false,
      ruleShow: false
    }
  },
  created() {
    localStorage.removeItem('haggleGoods')
    sessionStorage.removeItem('haggle_id')
    this.goodsId = this.$route.params.id
    this.$store.dispatch('getCart')
    if(this.$route.query.qorder && this.$route.query.qorder == 1) {
      this.isQOrder = true
    } else {
      this.isQOrder = false
    }
  },
  mounted(){
    this.getGoodsInfoHandle();
    this.getHaggleInfo()
    if(this.$route.query.pre && this.$route.query.pre == 1) {
      this.isNew = true
    }
  },
  methods:{
    goodsBack() {
      // 返回
      if(this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.go(-1)
      }
    },
    rule() {
      this.ruleShow = !this.ruleShow
    },
    // 内容滑动事件
    handleScroll(e){
      let a =  (e.target.scrollTop / 375) > 0.45  ?  0.45 : (e.target.scrollTop / 375)
      this.$refs.bar.style.background = `rgba(0,0,0, ${a})`
      if(e.target.scrollTop >= 500){
        this.scrollTop = true
      }else{
        this.scrollTop = false
      }
      this.$refs.bar.style.opacity = 0
      clearTimeout(this.timer)
      this.timer =  setTimeout( () => { 
        this.$refs.bar.style.opacity = 1
      },300) 
    },
    selectSku(data) {
      // 选择规格
      this.sharePrice = data.price
      this.sharePrePrice = data.price
      this.costPrice = data.costPrice
      this.shareBarcode = data.barcode
      this.selectSkuList = data.sku
    },
    // 点击  请选择
    handleShowSku(){
      this.goodsId = this.$route.params.id
      this.skuModal = true
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      this.cartAnimate = true

      let timer = setTimeout( () => {
        this.cartAnimate = false
        this.dotAnimate = true 

        
        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)

      },660)
      
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
    // 获取砍价商品详情
    getHaggleInfo() {
      haggle_detail(this.$route.query.id).then(res => {
        if(res) {
          this.haggleInfo = res.data
          if(this.haggleInfo.bargain_user_goods_sku) {
            this.haggleShow = true
          }
          this.skuBtn = `Super Price ${this.haggleInfo.bargain_goods_sku[0].sales_price} Open the Deal`
        }
      })
    },
    getGoodsInfoHandle(isPre = false) {
      // 获取商品详细信息
      getGoodsInfo(this.$route.params.id,{ pre: isPre ? 1 : this.$route.query.pre, sign: this.$route.query.sign}).then(res => {
        if(res) {
          this.off = res.data.off;
          this.goodsInfo = res.data;
          this.goodsInfoImg = res.data.goods_info.goods_details_url
          this.wxShare();
          if((this.goodsInfo.activity_min_price *1).toFixed(0) != 0) {
            this.sharePrice = this.goodsInfo.activity_min_price
          } else {
            this.sharePrice = (this.goodsInfo.min_price* (this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off / 100)).toFixed(2)
          }
          this.costPrice = this.goodsInfo.min_price
          if (this.$route.query.pre == 1) {
            this.sharePrePrice = (this.goodsInfo.pre_info.pre_min_price* (this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off / 100)).toFixed(2)
          }
          if(this.goodsInfo.pre_info.is_pre == 1) {
            if(!this.isPre && this.$route.query.pre == 0) {
              this.getGoodsInfoHandle(true)
            }
            this.isPre = true
            this.isNew = true
          }

          //保存主图以便分享使用
          if(this.$store.state.isMiniProgram && this.goodsInfo.round_pic_url.length > 0) {
            window.wx.miniProgram.postMessage({
              data: {
                path: this.$route.fullPath,
                mate: {title: this.goodsInfo.goods_info.name, content: {description: ''}},
                shareImage: this.goodsInfo.round_pic_url[0]
              }
            })
          }
        } else {
          this.$router.push('/en/offshelf')
        }
      })
    },
    favoriteGoodsHandle() {
      // 收藏商品
      let params = {
        status: this.goodsInfo.goods_collect == null ? 1 : 2
      }
      favoriteGoods(this.$route.params.id,params).then(res => {
        if(res) {
          if(this.goodsInfo.goods_collect == null) {
            this.$set(this.goodsInfo, 'goods_collect', 1)
          } else {
            this.$set(this.goodsInfo, 'goods_collect', null)
          }
          console.log(this.goodsInfo.goods_collect)
          this.$notify({ type: 'success', message: res.msg})
        }
      })
    },
    // 显示sku 弹窗
    showSkuModel(title) {
      // 判断是否登录状态
      if(this.isQOrder) {
        checkLogin().then(res => {
          if(res) {
            if(res.status == 0) {
              this.isViewLogin = true
            } else {
              this.skuBtn = title
              this.skuModal = true
            }
          } else {
            this.isViewLogin = true
          }
        })
      } else {
        this.skuBtn = title
        this.skuModal = true
      }
    },
    // 获取折扣
    handleBargain() {
      this.$router.push({path:`/en/haggleDetail/${this.haggleInfo.goods_id}`,query:{haggle_id:this.haggleInfo.bargain_user_goods_sku.id}})
    },
    shareHandle() {
      // 分享
      let data = {
        id: this.goodsInfo.id,
        img: this.goodsInfo.round_pic_url[0] || '',
        title: this.goodsInfo.goods_info.name,
        price: this.sharePrice,
        prePrice: this.sharePrePrice,
        costPrice: this.costPrice,
        barcode: this.shareBarcode,
        url: 'https://m.moobyyoho.com' + this.$route.fullPath,
        merchant_id: this.goodsInfo.shops_info.merchant_id
      }
      sessionStorage.setItem('share', JSON.stringify(data))
      this.$router.push({path: '/en/share',query:{pre:this.$route.query.pre}})
    },
    // 微信分享
    wxShare(){
      let jsUrl = '' // 验证签名所需URL
      let shareUrl = window.location.href // 分享链接的URL
      if (_isWechat() && _isIOS()) {
        // 微信浏览器&&苹果设备, 取出记录的第一次访问的URL
        jsUrl = this.$store.state.jsUrl
      } else {
        jsUrl = window.location.href; // 当前页面URL
      }
      let params = {
        url: jsUrl,
      }
      weshare(params).then(res => {
        if(res) {

           window.wx.config({
              debug: false, // 开启调试模式
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp:res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature,// 必填，签名
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                // "updateAppMessageShareData",
                // "updateTimelineShareData",
              ] 
          });

        
          const that = this;
           window.wx.ready( () =>{
             window.wx.checkJsApi({
                jsApiList: ["showMenuItems"],
                success: function () {
                      window.wx.showMenuItems({
                        menuList: [
                            'menuItem:share:appMessage', //发送给朋友

                            'menuItem:share:timeline' //分享到朋友圈
                        ]
                    });
                }
             });

             window.wx.onMenuShareAppMessage({
              title: "MOOBY YOHO", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0] || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || '好商品有我就够了 MoobyYoho 点击查看详情', // 分享描述
              success () {
                 that.$notify({ type: 'success', message: '分享成功' });
              },

            });
             window.wx.onMenuShareTimeline({
              title: "MOOBY YOHO", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0] || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || '好商品有我就够了 MoobyYoho 点击查看详情', // 分享描述
              success () {
                 that.$notify({ type: 'success', message: '分享成功' });
              },
            });
          })
        }
      })
    },
    loginSuccess() {
      // 登录成功
      this.isViewLogin = false
    }
  },
  watch: {
    '$route.params.id'(val) {
      if(val) {
        this.$refs.wrapper.scrollTop = 0
        this.selectSkuList = []
        this.getGoodsInfoHandle()
        this.getHaggleInfo()
        this.isNew = false
      }
    }
  },

  beforeRouteEnter: (to, from, next) => {
      /*
      *修复iOS版微信 默认是第一次进入应用的URL,这样就会导致错误
      * to.path  /详情/ID
      * location.hash #/xxxx
      */
     next()
      if (_isIOS() && to.path !== location.pathname) {
        location.assign(`${to.fullPath}`);
      } else {
        next();
      }
    
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
.goods-title {
  .title {
    padding-right: 58px;
  }
}
</style>